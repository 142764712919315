import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../assets/css/contact/contact.css';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import TopNav from '../components/topNav';
import Header from '../components/header';
import Footer from '../components/footer';
import PageHeader from '../components/pageHeader';

const Contact = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    phone: '',
    email: '',
    discipline: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');

    try {
      const transformedData = {
        full_name: `${formData.fname} ${formData.lname}`,
        email_address: formData.email,
        subject: formData.discipline,
        message: formData.message,
        hospital_name: 'Cohesive Staffing Solutions', 
        recipient_email: 'staffingteam@staffingok.com',
      };

      console.log('Submitting data:', transformedData);

      const response = await fetch('https://emails-dp0z.onrender.com/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedData),
      });

      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (response.ok) {
        setFormStatus('Message sent successfully!');
        setFormData({
          fname: '',
          lname: '',
          phone: '',
          email: '',
          discipline: '',
          message: '',
        });
      } else {
        setFormStatus('Failed to send message.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus('Failed to send message.');
    }
    
  };

  const mapStyles = {
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    height: '500px'
  };

  const iframeStyles = {
    height: '100%',
    width: '100%',
    border: 0
  };

  return (
    <div className='container contact-us'>
      <TopNav />
      <Header />
      <PageHeader
        imageUrl="/images/contact.jpg"
        title="Contact Us"
      />
      <div className='contents contact-page'>
        {/* <h1 className='dark-blue-title'>Get in Touch</h1> */}

        <div className='contact-address'>
          <div className='address-cards'>
            <div className='card'>
              <div className='icon-text'>
                <FontAwesomeIcon className='icon' icon={faLocationDot} />
                <h3>Location</h3>
              </div>
              <p>816 E. Federal, Shawnee, OK 74804</p>
            </div>
            <div className='card'>
              <div className='icon-text'>
                <FontAwesomeIcon className='icon' icon={faPhone} />
                <h3>Phone</h3>
              </div>
              <p>405-367-4422</p>
            </div>
            <div className='card'>
              <div className='icon-text'>
                <FontAwesomeIcon className='icon' icon={faEnvelope} />
                <h3>Email</h3>
              </div>
              <p>staffingteam@staffingok.com</p>
            </div>
          </div>
          <div className='contact-form'>
            <form onSubmit={handleSubmit}>
              <div className='half-input'>
                <div className='form-group'>
                  <label htmlFor='fname'>First Name</label>
                  <input
                    type='text'
                    name='fname'
                    id='fname'
                    value={formData.fname}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='lname'>Last Name</label>
                  <input
                    type='text'
                    name='lname'
                    id='lname'
                    value={formData.lname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='phone'>Phone number</label>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* <div className='form-group'>
                <label htmlFor='discipline'>Discipline</label>
                <select
                  name='discipline'
                  id='discipline'
                  value={formData.discipline}
                  onChange={handleChange}
                  required
                >
                  <option value=''>Select Discipline</option>
                  <option value='HR'>HR</option>
                  <option value='IT'>IT</option>
                  <option value='Finance'>Finance</option>
                  <option value='Marketing'>Marketing</option>
                  <option value='Sales'>Sales</option>
                  <option value='Operations'>Operations</option>
                  <option value='Other'>Other</option>
                </select>
              </div> */}
              <div className='form-group'>
                <label htmlFor='message'>Message</label>
                <textarea
                  name='message'
                  id='message'
                  cols='30'
                  rows='10'
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className='form-group'>
                <button type='submit' className='orange-btn'>Send Message</button>
              </div>
            </form>
            {formStatus && <p>{formStatus}</p>}
          </div>
        </div>

        <div style={mapStyles}>
      <div id="google-maps-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
        <iframe
          style={iframeStyles}
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=Shawnee,+OK+74804+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          allowFullScreen
        ></iframe>
      </div>
      <a className="embedded-map-code" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="enable-map-data">premium bootstrap themes</a>
      <style>
        {`
          #google-maps-canvas .text-marker {}
          .map-generator {
            max-width: 100%;
            max-height: 100%;
            background: none;
          }
        `}
      </style>
    </div>

      </div>
      <Footer />
    </div>
  );
};

export default Contact;
